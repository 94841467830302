
$(document).ready(function() { 
  if(tarteaucitron) {
    tarteaucitron.init({
      "privacyUrl": "", /* Privacy policy url */

      "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
      "cookieName": "tarteaucitron", /* Cookie name */

      "orientation": "bottom", /* Banner position (top - bottom) */

      "groupServices": false, /* Group services by category */
                      
      "showAlertSmall": false, /* Show the small banner on bottom right */
      "cookieslist": false, /* Show the cookie list */
                      
      "closePopup": false, /* Show a close X on the banner */

      "showIcon": true, /* Show cookie icon to manage cookies */
      "iconSrc": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAE0UlEQVRoge2ZX0wcRRzHP3PclZzlT6BCRShJ6wOgSTXBgAkvnE1qRZbCS2ssjZoYjOmf9FHFB5rUYuKT2DY0Jia1Pqg1tXQBkyaWvlQFKabWmNqkadpCSIpcRKCE9rjx4Sheb2d3Z/c2vpTP0+3M7/eb79z+duY3u7DKKo82Iogg8htyiFKHIEaSWkJUIykD8gAJdAmDbscY/byC5DggkXSIFr7XGTuclfAzbCDEHgTtQDmS1F8iMwyTnHYPRi9QDoDgOFCpo8HXBOQgJSQ4hOANYI2rQwE3/YyjQ8irgzR5jSWuIuhARzzAwvI/6xiYDmAcuE2IDl092s+AHCXCJMeAt3R90jgoDLp8+LmiNQFp8hjwLfCyz3EWSNIgtvOrT39bXFNIjhIhO/EAi0QYz8LfFvdnIJU22YgHOCGamLLrlCaHpcmcPMuHXgM7TkCa7EIj5/vOl3Lgoyr6hkpsAnHWJcQ+YC2CfW5jZWI7ATlICdCjE2RopIjFeyEuDBerDRJcdgwg6QHmEHrjpWN/BxIcAmwUPUysPk7umiSxurjaoJJ/nPxFC53CIF8084Gq3ynFlKuQHKSCJa6ju867EeFJsY1Jv+7SZJZUWTIrDArS+9R3IMFeghIPcJ+ns/J3SDHLHVguzG6Cxu6pL+BT0cL+wOKlYb0DUeoIUjyA4E3Zx/pAYy5jLeYEMUs1CSSWBOZQCcNXCgGo3zyD0ThFOEdhbCWPHHqk5FUhVNH9Y51AklrVo21eKOHcj+tWrs9dTP1u23JHbyTJDvq5LiWdQU7CmkKCKpXh8G+FlrafL1vbXHiPAb6SAzzhZCRPUyoHeE4noOo8UKarJuTnPCfZgaRJmpxAcIYl/qCAOyxQRJJnkWwHXidJWPbzMSG+IMoE82wE2kQzh9PDWVchk0UUS+h3P5SupM0DXmqYplWRQn3nSxkaKSJWH2d7zLYE8sM9YZCb3qB9oDEap9jaME1hfoLC/ARbG6ZpblSLcy0t/GN5dlQpNIeihAjnSNq23NF6aGP1cYaGi+1LC//MZjaoUuh34JmgR3bCQ8pdEQab0xtUKXQtUHUaeEi5PzMbVMvoaEC6tHGtZh+g0GZNobO8gOCn4OQFiKROtPBLepP1DowxAtz+vzR54BZjXMpsVJ8HTLqBd52ixf+OcNIs48ZElI0VC+w2JikuvB+QViXdwuD9zEbfB5pPTlZy9cbaleuaTfPsb7+ltM2yEARYBJ4SBhOZHcqNTDQxDnzuFPHGRPTh6/GojeV/heDMbJiZ2TDnLq7DvGDzAkDNZyrx4LQTR+gE/rLr3lix4HidjqoQVLXZMA0ctOu0nYDYRtzpNcfu5klqNs2TuyZJzaZ52g3fR15nBO8Iw/6PdK0nZT+9SN7ORoOXQjCDo8Jgr5OB++v1u+whynqg1dXWBmO56HuQNvWbZ2wLwTQGyOOAm5GXl7ungCYd+wDoB3YKg7tuhlrltDC4SxmtQG+2yjQ4Sh5tOuLBxzcyabITOAI87tXXhSkEe0Qzp7w4ef5CIwy+5j7VCI6R2mCyZRE4QoRqr+Ihy6+U0qQc2AvsAjZ4dL8NfElqpVFuUjoE85m1ixC1PA+8CNQCVaRejuUtm8yR+v51DcEoSc4zxiXRRTKI8VdZ5VHmX0gyfNlLLjkjAAAAAElFTkSuQmCC", /* Optionnal: URL or base64 encoded image */
      "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */

      "adblocker": false, /* Show a Warning if an adblocker is detected */
                      
      "DenyAllCta" : true, /* Show the deny all button */
      "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
      "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
                      
      "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

      "removeCredit": true, /* Remove credit link */
      "moreInfoLink": true, /* Show more info link */

      "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
      "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

      //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
                      
      "readmoreLink": "", /* Change the default readmore link */

      "mandatory": true, /* Show a message about mandatory cookies */
    });
  }
});